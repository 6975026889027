import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=6184c7a1&"
import script from "./Catalog.vue?vue&type=script&lang=ts&"
export * from "./Catalog.vue?vue&type=script&lang=ts&"
import style0 from "./Catalog.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Catalog.vue?vue&type=style&index=1&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCheckbox,VIcon,VTooltip})
